<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">List All FAQ</h2>
              <router-link :to="{ name: 'addFAQ' }" class="btn btn-brand-ar-vr">Add New FAQ</router-link>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column min-width="200px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button @click.native="handleEdit($index, row)" type="edit-vr" size="sm">
                    <span>Edit</span>
                  </base-button>
                  <base-button @click.native="handlePopDelete($index, row)" type="danger" size="sm">
                    <span>Delete</span>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="showModalDeleteFaq" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Delete FAQ</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="delete-faq">delete</span> this FAQ ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button
            outline
            type="primary"
            class="my-4"
            @click="showModalDeleteFaq = false"
          >Cancel</base-button>
          <base-button type="delete-vr" class="my-4" @click="handleDelete">Delete</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listCoursesMixin from './Mixins/listCoursesMixin'
import { getAllFAQ, deleteFAQ } from '@/services/faq'
import Fuse from "fuse.js";
export default {
  mixins: [listCoursesMixin],
  name: "ListCourses",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['updatedAt', 'question',],
      tableColumns: [
        {
          prop: 'updatedAt',
          label: 'Date Update',
          minWidth: 180,
          sortable: true
        },
        {
          prop: 'question',
          label: 'Question',
          minWidth: 250,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "faq",
          alias: "List FAQ"
        }
      ],
      selectedFaqId: null,
      showModalDeleteFaq: false,
    };
  },
  methods: {
    handleEdit(index, row) {
      this.$router.push({ name: 'editFAQ', params: { id: row.id } })
    },
    handlePopDelete(index, row) {
      this.selectedFaqId = row.id
      this.showModalDeleteFaq = true
    },
    async handleDelete() {
      try {
        const { data } = await deleteFAQ(this.selectedFaqId)
        this.showModalDeleteFaq = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "FAQ Successfuly Delete"
        });
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getAllFAQ() {
      try {
        const { data } = await getAllFAQ()
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
  },
  mounted() {
    this.getAllFAQ()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.status-active {
  &.text {
    color: $status-pass-ar-vr;
  }
  &.button {
    background-color: $status-failed-ar-vr;
    color: #fff;
    border: none;
    min-width: 80px;
    padding: 0;
  }
}
.status-not-active {
  &.text {
    color: $status-failed-ar-vr;
  }
  &.button {
    background-color: $status-pass-ar-vr;
    color: #fff;
    border: none;
    min-width: 80px;
    padding: 0;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .delete-faq {
    color: $alert-ar-vr;
  }
}
</style>